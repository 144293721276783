<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="brands !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="deleteBrand"
      >
        <span>آیا از حذف کردن این برند اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="بروزرسانی برند"
          @ok.preventDefault()="updateBrand"
      >
        <b-row v-if="selectedBrand!==null">
          <b-col cols="12">
            <!--            <b-media class="my-2">-->
            <!--              <template>-->
            <!--                <b-avatar-->
            <!--                    class="w-100 cursor-pointer flex-wrap"-->
            <!--                    ref="previewEl"-->
            <!--                    :src="picForUpdate"-->
            <!--                    size="300px"-->
            <!--                    rounded-->
            <!--                    @click.native="$refs.refInputEl1.click()"-->
            <!--                >-->
            <!--                  <feather-icon v-if="picForUpdate === null" icon="PlusSquareIcon" size="100"/>-->
            <!--                </b-avatar>-->
            <!--              </template>-->
            <!--              <div class="d-none flex-wrap">-->
            <!--                <b-button-->
            <!--                    variant="primary"-->
            <!--                >-->
            <!--                  <input-->
            <!--                      ref="refInputEl1"-->
            <!--                      type="file"-->
            <!--                      class="d-none"-->
            <!--                      accept="image/*"-->
            <!--                      @input="makeBase64PicForUpdate($event,'picForUpdate')"-->
            <!--                  >-->
            <!--                  <span class="d-none d-sm-inline">+</span>-->
            <!--                  <feather-icon-->
            <!--                      icon="EditIcon"-->
            <!--                      class="d-inline d-sm-none"-->
            <!--                  />-->
            <!--                </b-button>-->
            <!--              </div>-->
            <!--            </b-media>-->
            <b-form-group>
              <label>نام برند</label>
              <b-form-input
                  v-model="selectedBrand.title"
                  placeholder="نام برند"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <!--          &lt;!&ndash; Field:  description &ndash;&gt;-->
          <!--          <b-col cols="12" class="mt-2">-->
          <!--            <b-form-group-->
          <!--                label="توضیحات"-->
          <!--                label-for="blog-content"-->
          <!--                class="mb-2"-->
          <!--            >-->
          <!--              <b-form-textarea-->
          <!--                  v-model="SelectedCategory.seoDescription"-->
          <!--              ></b-form-textarea>-->
          <!--            </b-form-group>-->
          <!--            <span class=""-->
          <!--                  :class="{'text-danger':SelectedCategory.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{-->
          <!--                SelectedCategory.seoDescription.length-->
          <!--              }}</small><small-->
          <!--                class="text-secondary">/</small><small class="text-secondary">160</small></span>-->
          <!--          </b-col>-->
        </b-row>
      </b-modal>
      <b-modal
          id="modal-Add"
          cancel-title="بستن"
          centered
          ok-title="ثبت"
          title="افزودن برند (اصلی)"
          @ok.preventDefault()="createBrand"
      >
        <b-row>
          <b-col cols="12">
            <!--            <b-media class="my-2">-->
            <!--              <template>-->
            <!--                <b-avatar-->
            <!--                    class="w-100 cursor-pointer flex-wrap"-->
            <!--                    ref="previewEl"-->
            <!--                    :src="pic"-->
            <!--                    size="300px"-->
            <!--                    rounded-->
            <!--                    @click.native="$refs.refInputEl1.click()"-->
            <!--                >-->
            <!--                  <feather-icon v-if="newCategory.fileData === null" icon="PlusSquareIcon" size="100"/>-->
            <!--                </b-avatar>-->
            <!--              </template>-->
            <!--              <div class="d-none flex-wrap">-->
            <!--                <b-button-->
            <!--                    variant="primary"-->
            <!--                >-->
            <!--                  <input-->
            <!--                      ref="refInputEl1"-->
            <!--                      type="file"-->
            <!--                      class="d-none"-->
            <!--                      accept="image/*"-->
            <!--                      @input="makeBase64Pic($event,'pic')"-->
            <!--                  >-->
            <!--                  <span class="d-none d-sm-inline">+</span>-->
            <!--                  <feather-icon-->
            <!--                      icon="EditIcon"-->
            <!--                      class="d-inline d-sm-none"-->
            <!--                  />-->
            <!--                </b-button>-->
            <!--              </div>-->
            <!--            </b-media>-->

            <b-form-group>
              <label>نام برند</label>
              <b-form-input
                  v-model="newBrand.title"
                  placeholder="نام برند"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <!--          &lt;!&ndash; Field:  description &ndash;&gt;-->
          <!--          <b-col cols="12" class="mt-2">-->
          <!--            <b-form-group-->
          <!--                label="توضیحات"-->
          <!--                label-for="blog-content"-->
          <!--                class="mb-2"-->
          <!--            >-->
          <!--              <b-form-textarea-->
          <!--                  v-model="newCategory.seoDescription"-->
          <!--              ></b-form-textarea>-->
          <!--            </b-form-group>-->
          <!--            <span class=""-->
          <!--                  :class="{'text-danger':newCategory.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{-->
          <!--                newCategory.seoDescription.length-->
          <!--              }}</small><small-->
          <!--                class="text-secondary">/</small><small class="text-secondary">160</small></span>-->
          <!--          </b-col>-->
        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="8" md="12">
              <debouncer :placeHolder="`جستجو بر اساس نام برند`" @setValue="getsearchValue"></debouncer>
            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  class="w-100"
                  v-b-modal.modal-Add
                  variant="success"
              >
                افزودن برند جدید
              </b-button>
            </b-col>
            <b-col class="my-2">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>

            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="brands"
            bordered
            class="position-relative"
            :sort-by.sync="sortBy"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">
              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedBrand(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>

              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex  flex-row"
                   @click="setSelectedBrand(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>

          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  CreateBrand,
  UpdateBrand,
  DeleteBrand,
  GetAllBrand
} from "@/libs/Api/brands";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست برند ها  - پنل ادمین انزا مد',
  name: 'BrandsList',
  data() {
    return {
      pic: null,
      baseURL: Helper.baseUrl,
      showOverlay: false,
      sortBy: '',
      brands: null,
      totalCount: null,
      myTableColumns: [
        {
          key: 'brandId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'title',
          label: 'نام',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },


        // {
        //   key: 'SEO',
        //   label: 'SEO'
        // },
        // { key: 'parentId'},
      ],
      currentPage: 1,
      perPage: 10,
      search: '',
      selectedBrand: null,
      newBrand: {
        title: '',
        description: "",
        createDate: new Date(Date.now()),
        fileData: ""
      }
    }
  },
  async created() {
    await this.getAllBrands()
  },
  components: {
    Debouncer,
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  watch: {
    currentPage: function () {
      this.getAllBrands()
    }
  },

  methods: {
    getsearchValue(val) {
      this.search = val
      this.getAllBrands()

    },
    async updateBrand() {
      let _this = this
      _this.showOverlay = true;
      let updateBrand = new UpdateBrand(_this)

      updateBrand.setParams(_this.selectedBrand)
      await updateBrand.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برند بروزرسانی شد`,
          },
        })
        _this.selectedBrand = null
        _this.showOverlay = false;
        _this.getAllBrands();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },

    async createBrand() {
      let _this = this;
      _this.showOverlay = true;
      if (_this.newBrand.title !== '') {
        let createBrand = new CreateBrand(_this);
        createBrand.setParams(_this.newBrand)
        await createBrand.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `برند ساخته  شد`,
            },
          })
          _this.getAllBrands();
          _this.newBrand = {
            title: '',
          }
          _this.showOverlay = false;
        }, function (error) {
          _this.showOverlay = false;
          console.log(error)
        })
      } else {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا فیلد هارا پر کنید`,
          },
        })
      }
    },
    async deleteBrand() {
      let _this = this
      let deleteBrand = new DeleteBrand(_this)
      deleteBrand.setParams({id: this.selectedBrand.brandId})
      await deleteBrand.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برند  حذف شد.`,
          },
        })
        _this.getAllBrands();
      }, function (error) {
        console.log(error)
      })
    },
    setSelectedBrand(item) {
      this.selectedBrand = JSON.parse(JSON.stringify(item))
    },
    async getAllBrands() {
      let _this = this
      _this.showOverlay = true;
      let getAllBrand = new GetAllBrand(_this)
      let data = {
        pageNumber: _this.currentPage,
        searchCommand: _this.search,
        count: 10,
      }
      getAllBrand.setParams(data)
      await getAllBrand.fetch(function (content) {
        _this.brands = content.data.requests
        _this.totalCount = content.data.requestsCount
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    // async readAsDataURL(file) {
    //   return new Promise((resolve, reject) => {
    //     const fr = new FileReader();
    //     fr.onerror = reject;
    //     fr.onload = () => {
    //       resolve(fr.result.split(",")[1]);
    //     }
    //     fr.readAsDataURL(file);
    //   });
    // },
    // async makeBase64Pic(e, index) {
    //   const _this = this;
    //   let file = e.target.files[0];
    //   _this[index] = URL.createObjectURL(file);
    //   _this.newCategory.fileData = await _this.readAsDataURL(file);
    // },
    // async makeBase64PicForUpdate(e, index) {
    //   const _this = this;
    //   let file = e.target.files[0];
    //   _this[index] = URL.createObjectURL(file);
    //   _this.SelectedCategory.fileData = await _this.readAsDataURL(file);
    // },
  },
}
</script>

<style scoped>

</style>
